import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [text, setText] = useState("");
  const navigate = useNavigate();

  const handleTextareaChange = ({ target: { value } }) => {
    setText(value);
  };

  const startMemorizingDisabled = !text.trim();

  const handleStartMemorizingClick = () => {
    if (!startMemorizingDisabled) {
      localStorage.setItem("memorizeText", text);
      navigate("/memorize");
    }
  };

  return (
    <div className="d-flex flex-column gap-4 align-items-center mx-auto my-4">
      <h3>Memorizing, made easier.</h3>
      <p className="text-center">
        Paste the text you'd like to memorize in the box below
      </p>
      <div className="d-flex flex-column align-items-center gap-1 w-50">
        <textarea
          className="form-control border border-secondary w-100"
          rows={15}
          value={text}
          onChange={handleTextareaChange}
        />
      </div>
      <button
        disabled={startMemorizingDisabled}
        className="btn py-2 px-4 rounded-1"
        onClick={handleStartMemorizingClick}
      >
        Start Memorizing
      </button>
    </div>
  );
};

export default Home;
