import React, { useState } from "react";
import { Link } from "react-router-dom";

const Memorize = () => {
  const storedText = localStorage.getItem("memorizeText");
  const words = storedText ? storedText.split(/\s+/) : [];

  const [hiddenWords, setHiddenWords] = useState([]);
  const [level, setLevel] = useState(1);
  const [instruction, setInstruction] = useState("");

  const lines = [];
  for (let i = 0; i < words.length; i += 5) {
    const line = words.slice(i, i + 5);
    const lineDivs = line.map((word, index) => {
      const wordIndex = i + index;
      const hiddenCount =
        level > 1 && wordIndex < hiddenWords.length
          ? hiddenWords[wordIndex]
          : 0;
      const isHidden = hiddenCount > 0;
      const displayedWord = isHidden ? "" : word;
      return (
        <div
          className={`bg-light p-2 px-4 text-dark border rounded-1 ${
            isHidden ? "hidden-word pointer shadow-sm" : ""
          }`}
          key={index}
          onClick={() => handleWordClick(wordIndex)}
        >
          {displayedWord}
        </div>
      );
    });
    lines.push(
      <div className="d-flex gap-2" key={i}>
        {lineDivs}
      </div>
    );
  }

  const handleNextLevelClick = () => {
    let wordsToHide;
    let newLevel;
    let newInstruction;

    if (level === 1) {
      wordsToHide = 1;
      newLevel = 2;
      newInstruction =
        "Words have been removed from each line. Try reading the text without mistakes and filling in the missing words. Click on the empty word to reveal it, and check if you were right. If you make a mistake, click retry and start again.";
    } else if (level === 2) {
      wordsToHide = 2;
      newLevel = 3;
      newInstruction =
        "You get the drill now - 2 words have been removed per line. Make sure to check you got each word right before you progress and be honest with yourself and retry if you get one wrong.";
    } else if (level === 3) {
      wordsToHide = 4;
      newLevel = 4;
      newInstruction =
        "It's getting more difficult now. Retry the level if it takes more than a few second to remember the word until you are confidently able to get all of them.";
    } else {
      wordsToHide = 5;
      newLevel = 5;
      newInstruction =
        "Now try saying it completely. Great! Repeat these steps every hour or so until they're memorized.";
    }

    const newHiddenWords = generateHiddenWords(words, wordsToHide);
    setHiddenWords(newHiddenWords);
    setLevel(newLevel);
    setInstruction(newInstruction);
  };

  const handlePreviousLevelClick = () => {
    let newLevel = level - 1;
    if (newLevel < 1) {
      newLevel = 1;
    }
    let newInstruction = "";

    if (newLevel === 1) {
      newInstruction =
        "Your text has been split up into lines of five words. After each level, some words will be removed. For this first level, simply say it at least a few times.";
    } else if (newLevel === 2) {
      newInstruction =
        "Words have been removed from each line. Try reading the text without mistakes and filling in the missing words. Click on the empty word to reveal it, and check if you were right. If you make a mistake, click retry and start again.";
    } else if (newLevel === 3) {
      newInstruction =
        "You get the drill now - 2 words have been removed per line. Make sure to check you got each word right before you progress and be honest with yourself and retry if you get one wrong.";
    } else if (newLevel === 4) {
      newInstruction =
        "It's getting more difficult now. Retry the level if it takes more than a few seconds to remember the word until you are confidently able to get all of them.";
    }

    const newHiddenWords = generateHiddenWords(words, newLevel - 1);
    setHiddenWords(newHiddenWords);
    setLevel(newLevel);
    setInstruction(newInstruction);
  };

  const generateHiddenWords = (words, wordsToHide) => {
    const hiddenWords = [];
    for (let i = 0; i < words.length; i += 5) {
      const lineHiddenWords = Array(5).fill(0);
      const lineWords = words.slice(i, i + 5);
      const randomIndices = generateRandomIndices(
        lineWords.length,
        wordsToHide
      );
      randomIndices.forEach((index) => {
        lineHiddenWords[index] = 1;
      });
      hiddenWords.push(...lineHiddenWords);
    }
    return hiddenWords;
  };

  const generateRandomIndices = (length, count) => {
    const indices = Array.from({ length }, (_, index) => index);
    for (let i = indices.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [indices[i], indices[j]] = [indices[j], indices[i]];
    }
    return indices.slice(0, count);
  };

  const handleWordClick = (index) => {
    if (hiddenWords[index] > 0) {
      const updatedHiddenWords = [...hiddenWords];
      updatedHiddenWords[index]--;
      setHiddenWords(updatedHiddenWords);
    }
  };

  const handleRetryClick = () => {
    let wordsToHide;

    if (level === 4) {
      wordsToHide = 4;
    } else {
      wordsToHide = level - 1;
    }

    const newHiddenWords = generateHiddenWords(words, wordsToHide);
    setHiddenWords(newHiddenWords);
  };

  return (
    <div>
      <div className="d-flex flex-column gap-4 align-items-center mx-auto my-4">
        <h3>{level === 5 ? "Final Level" : `Level ${level}`}</h3>
        <p className="text-center w-50">
          {level === 1
            ? "Your text has been split up into lines of five words. After each level, some words will be removed. For this first level, simply say it at least a few times."
            : instruction}
        </p>
        <div className="d-flex justify-content-center flex-column gap-4 mt-4">
          {lines}
        </div>
        <div className="fixed bottom-0 w-50 d-flex justify-content-between px-4 py-2 bg-light rounded-1">
          <button
            className="btn btn-secondary py-2 px-4 rounded-1"
            onClick={handlePreviousLevelClick}
            disabled={level === 1} // Disable the button if it's the first level
          >
            Previous Level
          </button>
          <button
            className="btn btn-secondary py-2 px-4 rounded-1"
            disabled={level === 1 || level === 5}
            onClick={handleRetryClick}
          >
            Retry
          </button>
          <button
            className="btn btn-secondary py-2 px-4 rounded-1"
            onClick={handleNextLevelClick}
            disabled={level === 5}
          >
            Next Level
          </button>
        </div>
        <div className="mx-auto text-center">
          <Link to="/" className="text-decoration-none ms-4">
            Back Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Memorize;
